<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary"></v-progress-linear>
        <v-row>
          <v-spacer></v-spacer>
          <ca-lang-selector class="me-3" />
        </v-row>
        <v-card-text class="text-center">
          <v-avatar size="60" class="mb-4"> <img src="@/assets/images/logo.svg" alt="" /> </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("auth.sign_in_to_your_account") }}
          </h6>

          <ca-alert v-if="errorMessage" type="error"><div v-html="errorMessage"></div></ca-alert>

          <ca-alert v-if="showRegisterSuccessMessage" type="success">
            {{ $t("auth.register_success_message") }}
          </ca-alert>

          <v-form ref="form">
            <v-text-field
              v-model="email"
              :label="$t('auth.email')"
              :rules="emailRules"
              required
              :error-messages="fieldsErrors.email"
            />

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              :label="$t('auth.password')"
              :rules="nameRules"
              :error-messages="fieldsErrors.password"
              @click:append="show = !show"
            ></v-text-field>

            <captcha v-model="captcha" :is-active="showCaptcha" :is-debug="debugCaptcha" />

            <v-btn class="mb-4" block color="primary" dark @click="formSubmit">
              <v-icon left>mdi-login</v-icon>
              {{ $t("auth.sign_in") }}
            </v-btn>
            <div class="d-flex justify-around flex-wrap">
              <v-btn text small color="primary" class="mb-2" :to="{ name: 'restore-password' }">
                {{ $t("auth.forgot_password") }}
              </v-btn>
              <v-btn text small color="primary" :to="{ name: 'register' }">
                {{ $t("auth.create_new_account") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import CaAlert from "@/views/components/Alert";
import Captcha from "@/views/components/Captcha.vue";

export default {
  name: "Login",
  components: {Captcha, CaAlert },
  metaInfo() {
    return {
      title: this.$t("titles.sign_in")
    };
  },
  data() {
    return {
      showRegisterSuccessMessage: false,
      errorMessage: null,
      show: false,
      email: "",
      password: "",
      showCaptcha: false,
      debugCaptcha: true,
      captcha: false,
      loading: false,
      emailRules: [
        v => !!v || this.$t("auth.email_required"),
        v => /.+@.+\..+/.test(v) || this.$t("auth.email_must_be_valid")
      ],
      nameRules: [v => !!v || this.$t("auth.password_required")],
      fieldsErrors: {
        email: [],
        password: [],
        captcha: null
      }
    };
  },

  computed: {
    captchaErrorMessage() {
      return this.$t("auth.enter_captcha");
    }
  },

  mounted() {
    this.loadCaptchaState();

    this.showRegisterSuccessMessage = this.$route.query.success;
  },
  methods: {
    formSubmit() {
      if (this.loading) {
        return;
      }

      this.errorMessage = null;

      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.showCaptcha && !this.debugCaptcha && !this.captcha) {
        this.errorMessage = this.captchaErrorMessage;
        return;
      }

      this.loading = true;
      this.errorMessage = null;
      this.emptyFieldErrors();

      let data = {
        email: this.email,
        password: this.password
      };

      if (this.showCaptcha && !this.debugCaptcha) {
        data["captcha"] = window.grecaptcha.getResponse();
      } else {
        data['captcha'] = 'debug-captcha-result'
      }

      this.$store
        .dispatch("auth/login", data)
        .then(response => {
          if (response.data.redirect && response.data.redirect === "get-started") {
            window.location.href = "/start";
          } else {
            window.location.href = "/";
          }
        })
        .catch(response => {
          this.loading = false;
          this.captcha = false;

          this.loadCaptchaState();

          if (response.status === 422) {
            this.fieldsErrors = response.data.errors;

            if (this.fieldsErrors.captcha) {
              this.errorMessage = this.captchaErrorMessage;
            }
          } else if (response.status === 429) {
            this.errorMessage = response.data.message;
          } else if (response.status === 400) {
            this.fieldsErrors.email = response.data.error;
          }
        });
    },

    emptyFieldErrors() {
      this.fieldsErrors = {
        email: [],
        password: [],
        captcha: null
      };
    },

    loadCaptchaState() {
      this.axios
        .get("/captcha/login")
        .then(response => {
          this.showCaptcha = response.data.active;
          this.debugCaptcha = response.data.debug;
        })
        .catch(() => {
          alert("Some error happened");
        });
    }
  }
};
</script>
